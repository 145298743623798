@import "../../styles/helpers";

.asset-operation {
  max-width: 1056px;
  margin: 0 auto;
}

.asset--operation {
  color: $white;
  font-size: 14px;
  h3 {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 0;
  }
  .operation-row-wrapper {
    padding: 12px;
    border-bottom: 1px solid $n2;
    .cell-header {
      color: $n15;
    }
    .wallet-row {
      background: $n2;
      padding: 8px;
      border-radius: 4px;
      border: 1px dashed $n18;
      margin-top: 5px;
    }
    .wallet--cell {
      display: grid;
      grid-template-columns: 6fr 6fr;
      gap: 10px;

      .wallet-header {
        color: $n15;
        padding-bottom: 6px;
        span {
          color: $white;
        }
      }
      .blockchain-hash{
        cursor: pointer;
      }
      .wallet--cell-border {
        &:first-child {
          border-right: 1px solid $s6;
        }
        .wallet-polygonData {
          background: #ffffff0a;
          padding: 8px;
          border-radius: 4px;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          &.wallet-left {
            margin-right: 8px;
          }
          .text-ellipse {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
          }
        }
      }
      .copyIcon {
        margin-left: 10px;
        color: $n15;
      }
    }
    .yellowDot {
      width: 6px;
      display: inline-block;
      height: 6px;
      background: $s5;
      border-radius: 5px;
      margin: 2px 4px;
    }
    .greyDot {
      width: 6px;
      display: inline-block;
      height: 6px;
      background: $n15;
      border-radius: 5px;
      margin: 2px 4px;
    }
  }
  .operation-row {
    display: flex;
    justify-content: space-between;
    .operation-cell {
      width: 45%;
      &:first-child {
        width: 35%;
      }

      &:last-child {
        width: 16%;
      }
    }
    .operation--cell {
      display: flex;
      justify-content: space-between;
      padding: 4px 0;
    }
    .operation-symbol {
      font-size: 16px;
      font-weight: 600;
    }
    .operation-blockchain{
      display: flex;
      align-items: center;
      gap:4px;
    }
    .operation-blockchain-title{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      }
    .operation-blockchain-logo{
      width: 20px;
      height: 20px;
    }

    .buy-cell {
      width: 6px;
      display: inline-block;
      height: 6px;
      background: $n13;
      border-radius: 5px;
      margin: 2px 4px;
    }
    .sell-cell {
      width: 6px;
      display: inline-block;
      height: 6px;
      background: $s7;
      border-radius: 5px;
      margin: 2px 4px;
    }
    .excuted-cell {
      width: 6px;
      display: inline-block;
      height: 6px;
      background: $s8;
      border-radius: 5px;
      margin: 2px 4px;
    }
    .text-ellipse {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      margin-left: 4px;
      white-space: nowrap;
      &.databaseId {
        max-width: 400px;
      }
    }
    .text-ellipse-wallet {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }
    .operatio-cell-copy {
      display: flex;
      justify-content: space-between;
      padding: 4px 6px;
      // height: 22px;
      button {
        display: none;
        background: #4574f506;
        border: 1px solid #36383d;
        margin: 0 2px;
        border-radius: 4px;
        color: $white;

        align-items: center;
        padding: 3px 9px;
        cursor: pointer;
        font-size: 12px;
        i {
          color: $n15;
          margin-right: 4px;
          font-size: 16px;
        }
      }

      &.hover-cell:hover {
        background: #ffffff08;
        button {
          display: flex;
        }
        .text-ellipse {
          max-width: 90px;
          &.databaseId {
            max-width: 250px;
          }
        }
      }
    }
    .copy-cell-row {
      display: flex;

      align-items: center;
    }
  }
  .operation-header {
    background: $n2;
    font-size: 12px;
    color: $n16;
  }
  .empty-data {
    text-align: center;
  }
}
