@import styles/helpers
.currencySearch
    position: relative
    z-index: 11

.form
    position: relative
    margin: 12px 0
    // border-bottom: 1px solid $n6
    +dark
        // border-bottom: 1px solid $n2

.searchData
    position: absolute
    z-index: 1
    background: $white
    width: 100%
    height: calc( 100vh - 120px)
    overflow: auto
    +scroll
    +dark
        background: $dark

.input
    width: 1000px
    padding: 10px 40px 10px 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 10px
    right: 5px
    bottom: 0
    width: 33px
    cursor: auto
    background: transparent
    border: none
    i
        font-size: 17px
        color: #777E90
    svg
        fill: $n4
        transition: fill .2s

.table
    display: flex
    flex-direction: column
    width: 100%
    max-height: calc( 100vh - 134px)
    overflow-y: auto
    +scroll

.row
    border-bottom: 1px solid $n6
    padding: 8px 16px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    cursor: pointer
    +poppins
    +dark
        border-color: $n2
   
    &.active
        background: $n6
        +dark
            background: rgba(255, 255, 255, 0.03)

    .left
        width: 60%
        display: grid
        grid-template-columns: 32px auto
        column-gap: 12px
        align-items: center
        row-gap:  4px
        .noImageText 
            grid-row: 1 / span 2
            width: 32px
            height: 32px
            border-radius: 50%
            object-fit: contain
            font-size: 16px
            background: #f7f7f7
            +dark
                background: $n2
            display: flex
            border-radius: 50%
            justify-content: center
            align-items: center

    .symbol
        font-weight: 600
        font-size: 14px
        line-height: 20px
        +dark
            color: $white

    .image
        grid-row: 1 / span 2
        height: 32px
        width: 32px
        object-fit: contain
        border-radius: 50%
        
    .compony_name
        width: 100%
        font-weight: 500
        font-size: 12px
        line-height: 16px
        color: $n11
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    .right
        width: 40%
        display: flex
        flex-direction: column
        align-items: flex-end
        gap: 4px

    &:hover
        background: $n6
        +dark
            background: rgba(255, 255, 255, 0.03)

.noData
    width: 100%
    padding-top: 50px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 16px

// .loader 
//     z-index: 1
//     position: absolute
//     background: $white
//     height: calc( 100vh - 120px)
//     width: 100%
//     +dark
//         background: $dark

.icon
    font-size: 24px
.added
    color: $s3
.removed
    color: $n4
   

.noData
    width: 100%
    height: calc( 100vh - 134px)
    background: $white
    position: absolute
    z-index: 1  
    +dark
        background: $dark
    img
        width: 250px

.noDataContent
    position: absolute
    top:50px
    display: flex
    flex-direction: column
    align-items: center
    gap: 8px
    font-size: 14px
    img 
        height: 96px

.loaderInner
    position: absolute
    top:150px

.disabledContainer
    cursor: not-allowed
    opacity: 0.8

.disabledContent
    pointer-events: none

.closeBtn
    cursor: pointer

.assetImageContain 
    grid-row: 1 / span 2
    width: 32px
    height: 32px
    border-radius: 50%
    object-fit: contain
    font-size: 16px
    background: #f7f7f7
    +dark
        background: $n2
    display: flex
    border-radius: 50%
    justify-content: center
    align-items: center


.imagewrapper
   width: 32px
   height: 32px 
   border-radius: 50%
   position: relative
.imagefromcontiner
    width: 20px
    overflow: hidden
    border-radius: 8px
    z-index: 10
    margin-left: 7px
    position: relative
    top: -4px
    left: -4px
    .imagefrom
        border-radius: 8px 
        max-width: 20px
        max-height: 20px
.imagetocontiner
    width: 24px
    height: 20px
    position: relative
    border-radius: 8px 
    bottom:8px
    right: 8px
    .imageto
        border-radius: 8px 
        max-width: 20px
        max-height: 20px
        position: absolute
        left: 16px
        top: -8px
        width:100%
