@import ../../styles/helpers

.tablet_wrap
    +t
        display: flex
    +m 
        position: sticky
        top: 0
        z-index: 20

.header
    background: $n8
    box-shadow: inset 0 -1px 0 $n6
    width: 100%
    +dark
        box-shadow: inset 0 -1px 0 $n2
        background: $dark
        border-color: $n2

        +m
            box-shadow: none
    &.wide
        box-shadow: none
        .container
            max-width: 100%
            padding: 0 20px
            +m
                padding: 0 24px

.drawer_open_btn
    display: none
    +t
        display: block
        padding: 16px
        border-bottom : 1px solid #E6E8EC
        +dark
            border-bottom : 1px solid $n2
    +m 
        padding: 0 16px
        background: $white
        border-bottom : 1px solid #E6E8EC
        +dark 
            background: $dark
            border-bottom : none

    i
        font-size: 24px
        color: $dark
        +dark
            color: $white

.drawer_open
    display: none
    +t
        display: block

.container
    display: flex
    align-items: center
    +d
        padding-left: 40px
    +t
        padding: 0
    +m
        padding: 0 24px

.logo
    width: 383px
    object-fit: contain
    font-size: 12px
    +t
        margin-right: 16px
        display: none
        font-size: 18px
        font-weight: 700
    +m
        position: relative
        z-index: 15
        margin-right: auto
    img
        +t
            width: 42px

.logo1
    object-fit: contain
    font-size: 24px
    font-weight: 600
    color: #000
    padding: 0px 16px
    // text-align: center
    img
        border-radius: unset
    +dark
        color: $s3
    +t
        margin-right: 16px
    +m
        position: relative
        z-index: 15
        margin-right: auto
 

.picDesktop
    +t
        display: none

.picMobile
    display: none
    +t
        display: inline-block

.wrapper
    display: flex
    justify-content: space-between
    align-items: center
    flex-grow: 1
    padding: 8px 16px
    border-left: 1px solid $n6
    +x
        padding-left: 0
        border: none

    +dark
        border-color: $n3
    .theme
        +d
            display: none
        +t
            display: inline
    & > .btns
        +m
            display: none

.wrap
    // display: flex
    // justify-content: space-between
    +m
        display: block
        background: $white
        transition: all .2s
        +dark
            background: $dark
        &.visible
            visibility: visible
            opacity: 1
    & > .button
        display: none
        +m
            display: none
            margin: auto 24px 0
    .btns
        display: none
        +m
            display: flex

.nav
    display: flex
    font-weight: 600
    +m
        gap: 8px
    .navItem
        cursor: pointer

.item,
.nav > div
    &:not(:last-child)
        margin-right: 40px
        +d
            margin-right: 32px
        +t
            margin-right: 20px
        +m
            margin: 0

.item
    +dm-sans
    font-size: 14px
    line-height: 40px
    font-weight: 700
    transition: all .2s
    color: $n1

    +dark
        color: $n8
    +m
        font-weight: 600
    &:hover,
    &.active

        +dark
        +m
            border-color: $p1

.control
    display: flex
    align-items: center
    .button
        +m
            display: none

.settings
    margin-right: 32px
    +d
        margin-right: 16px
    +t
        margin-right: 10px

.activity,
.notifications,
.control .button,
.theme
    margin-right: 20px
    +d
        margin-right: 16px
    +m
        margin-right: 12px

.activity
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 40px
    height: 40px
    +m
        margin-right: 4px
    svg
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        svg
            fill: $n2
            +dark
                fill: $n8

.btns
    display: flex
    +m
        margin: auto 16px 0
    .button
        +m
            flex: 0 0 calc(50% - 16px)
            width: calc(50% - 16px)
            margin: 0 8px
        &:not(:last-child)
            margin-right: 8px
            +m
                margin-right: 8px

.burger
    display: none
    +m
        display: block
        position: relative
        z-index: 15
        margin-left: 12px
        width: 32px
        height: 32px
        background: none
        -webkit-tap-highlight-color: rgba(0,0,0,0)
        &.active
            &:before
                transform: translateY(0) rotate(-45deg)
            &:after
                transform: translateY(0) rotate(45deg)
        &:before,
        &:after
            content: ''
            position: absolute
            top: 16px
            left: 6px
            width: 20px
            height: 2px
            background: $n4
            border-radius: 2px
            transition: transform .2s
        &:before
            transform: translateY(-4px)
        &:after
            transform: translateY(3px)

.user_container
    display: flex
    align-items: center
    gap: 10px

.containSettings 
    display: flex
    width: 100%
    justify-content: flex-end
    align-items: center

.activeTab
    color: #3C65D6
    
.brand_logo
    width: 210px
    height: 100%

.userDropdown
    margin-right: 24px
    // height: 40px
    padding:8px, 12px, 8px, 12px    

