@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap)
// media queries
=w
    @media only screen and (max-width: "1419px")
        @content
=x
    @media only screen and (max-width: "1339px")
        @content
=d
    @media only screen and (max-width: "1179px")
        @content
=t
    @media only screen and (max-width: "1023px")
        @content
=m
    @media only screen and (max-width: "767px")
        @content
=a
    @media only screen and (max-width: "639px")
        @content
=s
    @media only screen and (max-width: "474px")
        @content

// fonts
=dm-sans
    font-family: 'DM Sans', sans-serif

=poppins
    font-family: 'Poppins', sans-serif
    
// colors
$p1: #3772FF
$p2: #9757D7
$p3: #FF6838
$p4: #58BD7D
$s1: #4BC9F0
$s2: #E4D7CF
$s3: #FFD166
$s4: #CDB4DB
$s5: #F5AF45
$s6: #ffffff1f
$s7: #FF0000
$s8: #458bf5
$white: #ffffff
$dark: #17181b


$n1: #141416
$n2: #23262F
$n3: #353945
$n4: #777E90
$n5: #B1B5C3
$n6: #E6E8EC
$n7: #F4F5F6
$n8: #FCFCFD
$n9: #B8B8B8
$n10: #F5C462
$n11: #878C99
$n12: #36383D
$n13: #33B87A
$n14: #E6EAF5
$n15: #A2A7B8
$n16: #BCC3D6
$n17: #1B1C1F
$n18: #343946

//newcolor
 


// typography
=body-1
    font-size: 24px
    line-height: 1.3
    letter-spacing: -.01em

=body-bold-1
    font-size: 24px
    line-height: 1.3
    font-weight: 600
    letter-spacing: -.01em

=body-2
    font-size: 16px
    line-height: 1.5

=body-bold-2
    font-size: 16px
    line-height: 1.5
    font-weight: 500

=caption-1
    font-size: 14px
    line-height: 1.7

=caption-bold-1
    font-size: 14px
    line-height: 1.7
    font-weight: 500

=caption-2
    font-size: 12px
    line-height: 1.65

=caption-bold-2
    font-size: 12px
    line-height: 1.65
    font-weight: 600

=caption-3
    font-size: 10px
    line-height: 1.6

=hairline-1
    font-size: 16px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=hairline-2
    font-size: 12px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=button-1
    +dm-sans
    font-size: 16px
    font-weight: 700
    line-height: 1

=button-2
    +poppins
    font-size: 14px
    font-weight: 500

=button-3
    +poppins
    font-size: 12px
    font-weight: 400

=scroll
    &::-webkit-scrollbar
        width: 3px
    &::-webkit-scrollbar-track
        border-radius: 10px
          
    /* Handle */
    &::-webkit-scrollbar-thumb
        background:#777E90
        border-radius: 10px
        +dark
            background:#363a45

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
        background: #777E90
        +dark
            background:#363a45
            
//triangle
=arr($width, $height, $bg, $direction)
    width: 0px
    height: 0px
    border-style: solid
    @if $direction == t
        border-width: 0 $width / 2 + px $height + px $width / 2 + px
        border-color: transparent transparent $bg transparent
    @if $direction == r
        border-width: $height / 2 + px 0 $height / 2 + px $width + px
        border-color: transparent transparent transparent $bg
    @if $direction == b
        border-width: $height + px $width / 2 + px 0 $width / 2 + px
        border-color: $bg transparent transparent transparent
    @if $direction == l
        border-width: $height / 2 + px $width + px $height / 2 + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == tl
        border-width: $height + px $width + px 0 0
        border-color: $bg transparent transparent transparent
    @if $direction == tr
        border-width: 0 $width + px $height + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == br
        border-width: 0 0 $height + px $width + px
        border-color: transparent transparent $bg transparent
    @if $direction == bl
        border-width: $width + px 0 0 $height + px
        border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
    &::placeholder
        @content

// media query width
=r($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

=text-overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

// theme
=dark
    @at-root :global(.dark-mode) &
        @content

=dark-body
    @at-root .dark-mode
        @content

=dark-common
    @at-root .dark-mode &
        @content